import { NavLink, useParams } from "react-router-dom";

function LogoText() {
  const { motiveSlug } = useParams();
  return (
    <NavLink
      to={motiveSlug ? "/motive/" + motiveSlug : "/"}
      className="flex justify-center -translate-y-[5px]"
      title="Maitri">
      <svg
        width="242"
        height="53"
        viewBox="0 0 242 53"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_640_7077-836749)">
          <path
            d="M88.6732 12.5362C88.4867 12.2196 88.1534 12.0273 87.7861 12.0273C87.4189 12.0273 87.0856 12.2139 86.8935 12.5362L81.5319 21.5612C80.5206 23.2633 80.5658 25.3499 81.6393 27.0068L82.9274 28.9972L87.7805 20.8204L105.611 50.8359C106.317 52.0234 107.56 52.7642 108.944 52.8151L112.673 52.9508L88.6732 12.5362Z"
            fill="black"
          />
          <path
            d="M68.2382 12.5132C68.0518 12.2022 67.7241 12.0156 67.3625 12.0156C67.0009 12.0156 66.6733 12.2022 66.4868 12.5132L48.5659 42.6813L30.6676 12.5471C30.4755 12.2248 30.1422 12.0325 29.7693 12.0325C29.3964 12.0325 29.0631 12.2248 28.871 12.5471L4.97266 52.9447L7.99526 52.8316C9.75797 52.7638 11.3512 51.8194 12.2495 50.3039L29.7693 20.8144L48.5659 52.4641L67.3682 20.8031L84.9727 50.3039C85.8766 51.8194 87.4755 52.7638 89.2382 52.826L92.3625 52.9391L68.2382 12.5132Z"
            fill="black"
          />
          <path
            d="M119.187 11.6094H118.701V48.3937C118.701 50.8818 120.724 52.9062 123.21 52.9062H123.696V16.1219C123.701 13.6395 121.679 11.6094 119.187 11.6094Z"
            fill="black"
          />
          <path
            d="M132.621 11.5018V11.9881C132.621 13.283 133.13 14.4988 134.045 15.4092C134.954 16.314 136.158 16.806 137.435 16.806C137.446 16.806 137.457 16.806 137.474 16.806L145.598 16.7438V48.2691C145.598 50.8929 147.728 53.0247 150.35 53.0247H150.836V11.4961H132.621V11.5018Z"
            fill="black"
          />
          <path
            d="M199.996 11.4784L199.504 11.4727V48.059C184.222 48.7207 180.803 43.637 177.171 38.2537C175.854 36.2971 174.498 34.284 172.634 32.5311C176.126 31.8638 178.837 30.7102 180.696 29.093C182.628 27.4135 183.651 25.293 183.741 22.7879C183.849 19.6382 182.77 17.037 180.538 15.0465C177.967 12.7564 173.82 11.665 167.075 11.4954L164.939 11.4897C162.436 11.484 159.408 11.4727 158.787 11.484C158.668 11.4897 158.289 11.5406 158.295 12.1174C158.306 13.1635 159.035 16.1605 162.109 16.1605C167.606 16.1605 174.272 16.4772 177.058 18.9653C178.086 19.8814 178.555 21.0745 178.504 22.607C178.47 23.6418 178.086 24.4221 177.267 25.1346C175.476 26.6897 171.594 27.8376 167.826 27.9168C166.323 27.9337 162.707 28.1882 161.572 30.19L161.131 30.9704L161.984 31.2418C167.702 33.057 174.301 43.3204 174.38 43.4448C177.815 48.2966 182.352 53.0352 196.431 53.0352H202.295L204.205 53.007L204.747 52.4528V16.0135C204.741 12.4849 201.64 11.501 199.996 11.4784Z"
            fill="black"
          />
          <path
            d="M202.075 -0.046875C200.346 -0.046875 198.934 1.34993 198.934 3.06333C198.934 4.77663 200.34 6.17343 202.075 6.17343C203.804 6.17343 205.216 4.77663 205.216 3.06333C205.216 1.34993 203.804 -0.046875 202.075 -0.046875Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_640_7077-836749">
            <rect width="242" height="53" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </NavLink>
  );
}

export default LogoText;
