import { NavLink, useParams } from "react-router-dom";

function LogoImage() {
  const { motiveSlug } = useParams();
  return (
    <NavLink
      to={motiveSlug ? "/motive/" + motiveSlug : "/"}
      title="Maitri"
      className="sticky top-5 flex justify-center">
      <svg
        width="59"
        height="51"
        viewBox="0 0 59 51"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M29.069 28.292C30.8038 28.292 32.2102 26.8996 32.2102 25.1819C32.2102 23.4642 30.8038 22.0718 29.069 22.0718C27.3341 22.0718 25.9277 23.4642 25.9277 25.1819C25.9277 26.8996 27.3341 28.292 29.069 28.292Z"
          fill="black"
        />
        <path
          d="M52.9329 25.1821C55.2945 22.2868 57.0177 19.2446 57.7408 16.2249C58.8312 11.6559 57.6617 7.45437 54.3453 4.07848C49.9386 -0.41706 43.7917 -1.21438 36.5657 1.77699C33.3227 3.11717 30.577 4.94366 29.0685 6.04068C27.5657 4.94366 24.8143 3.11717 21.5713 1.77699C14.351 -1.20873 8.19844 -0.411405 3.79166 4.07848C0.480924 7.46002 -0.694206 11.6615 0.39619 16.2249C1.11935 19.2446 2.84251 22.2925 5.20409 25.1821C2.84251 28.0773 1.11935 31.1196 0.39619 34.1392C-0.694206 38.7082 0.475274 42.9041 3.79166 46.2856C6.44138 48.9886 9.71821 50.3571 13.5374 50.3571C16.0289 50.3571 18.7352 49.7633 21.5713 48.5871C24.8143 47.2469 27.56 45.4205 29.0685 44.3234C30.5713 45.4205 33.3227 47.2469 36.5657 48.5871C39.4301 49.769 42.125 50.3627 44.6109 50.3627C48.3905 50.3627 51.69 48.9999 54.3453 46.2856C57.6617 42.9041 58.8312 38.7026 57.7408 34.1392C57.0177 31.1196 55.2945 28.0773 52.9329 25.1821ZM29.0685 14.3193C36.0798 16.8469 42.1081 20.9353 46.4018 25.1821C42.1081 29.4288 36.0742 33.5115 29.0685 36.0449C22.0572 33.5172 16.0289 29.4288 11.7352 25.1821C16.0289 20.9353 22.0572 16.8526 29.0685 14.3193ZM31.6787 10.1291C34.8934 7.75408 44.8482 1.30199 50.8934 7.46568C53.0403 9.65408 53.7408 12.1478 53.0346 15.094C52.5713 17.0335 51.3736 19.2728 49.5996 21.5856C44.5148 16.6943 37.8595 12.538 31.6787 10.1291ZM8.53744 21.5856C6.76342 19.2785 5.57132 17.0335 5.1024 15.094C4.39618 12.1478 5.09675 9.65408 7.24365 7.46568C8.93291 5.74663 11.0629 4.87014 13.5826 4.87014C18.7691 4.87014 24.238 8.48354 26.4583 10.1291C20.2775 12.538 13.6222 16.6943 8.53744 21.5856ZM26.4583 40.2294C23.2324 42.5987 13.255 49.0225 7.2493 42.8984C5.10241 40.71 4.40184 38.2163 5.10805 35.2701C5.57133 33.3306 6.76906 31.0913 8.54307 28.7785C13.6222 33.6699 20.2719 37.8261 26.4583 40.2294ZM49.5996 28.7785C51.3736 31.0856 52.5657 33.3249 53.0346 35.2701C53.7408 38.2163 53.0403 40.71 50.8934 42.8984C44.8369 49.0734 34.8877 42.61 31.6787 40.235C37.8595 37.8261 44.5148 33.6699 49.5996 28.7785Z"
          fill="black"
        />
      </svg>
    </NavLink>
  );
}

export default LogoImage;
